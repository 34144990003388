@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(../fonts/poppins/Poppins-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-Thin';
  src: local('Poppins'), url(../fonts/poppins/Poppins-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins'), url(../fonts/poppins/Poppins-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Expressway';
  src: local('Expressway'), url(../fonts/expressway/expressway_rg.otf) format('opentype');
}
@font-face {
  font-family: 'Expressway-Thin';
  src: local('Expressway'), url(../fonts/expressway/expressway_xl.otf) format('opentype');
}
@font-face {
  font-family: 'Expressway-Light';
  src: local('Expressway'), url(../fonts/expressway/expressway_lt.otf) format('opentype');
}

:root{
  --gradient-rot: 0.95turn;
  --font-size: 16px;
  --font-color: white;
  --color-primary: #2596be;
  --color-secondary: #165A73;
  --color-tertiary: #2dbaed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header 
{
  height: 90px;
  margin: 0;
  font-family: 'Expressway', sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: 'Expressway', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0E3A4A;
  color: var(--font-color);
  width: 100%;
  font-size: var(--font-size);
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

main {
  padding-top: 25px;
  margin: 0 auto;
  margin-bottom: 25px;
  width: 75%;
  position: relative;
  flex-grow: 1;
  min-height: calc(100vh - 220px); /* 100vh = 100% Viewport Height - 220px (paddings / margins navbar height / footer height) */
}

footer
{
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: rgba(255,255,255,0.035);
  padding-top: 20px;
  padding-bottom: 10px;
  margin: auto;
  text-align: center;
}

.footerLinks > span
{
  margin-left: 25px;
}

p
{
  margin-block-end: 0;
  margin-block-start: 0;
  line-height: 1.5em;
}

.center
{
  text-align: center;
}

.centerOnPage
{
  margin: auto;
  width: 100%;
}

h1
{
  text-align: center;
  margin-bottom: 1.5em;
  padding-bottom: 15px;
  border-bottom: 3px solid var(--color-secondary);
  font-family: 'Expressway', sans-serif !important;
}
h2
{
  font-family: 'Expressway', sans-serif !important;
}
h3
{
  margin-block-end: 0;
  margin-block-start: 0;
  font-family: 'Expressway', sans-serif !important;
}
h4
{
  font-family: 'Expressway', sans-serif !important;
}

.phantom
{
  display: block;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 60px;
  width: 100%;
}

a:link {
  color: var(--font-color);
  text-decoration: none;
}

/* visited link */
a:visited {
  color: var(--font-color);
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: var(--color-primary);
  text-decoration: none;
  font-weight: 700;
}

/* selected link */
a:active {
  color: var(--font-color);
  text-decoration: none;
}