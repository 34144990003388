.aboutCarousel img
{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 61vh;
  object-fit: cover;
}

.aboutCarousel pTitle
{
  position: absolute;
  bottom: 75px;
  left: 75px;
  font-size: calc(var(--font-size) * 4);
  background-color: rgba(0, 0, 0, 0.3);
  color: var(--font-color);;
}

.aboutCarousel pSubtitle
{
  font-weight: 700;
  position: absolute;
  bottom: 36px;
  left: 75px;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: calc(var(--font-size) * 1.6);
  color: var(--font-color);;
}