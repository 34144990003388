.section-links{
    margin-left: auto;
}

.special-link
{
    position: absolute;
    width: 200px;
    left: calc(50% - 100px);
    padding: 0;

    margin: 0 auto;
    font-weight: 700;
    font-size: 32px;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    animation: wobble 2s ease infinite;
}

@keyframes wobble {
    0%, 30%, 100% {
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
    }

    5% {
        -webkit-transform: translateX(-0px) rotate(-10deg);
        transform: translateX(-0px) rotate(-10deg);
    }

    10% {
        -webkit-transform: translateX(calc(0px / 2)) rotate(10deg);
        transform: translateX(calc(0px / 2)) rotate(10deg);
    }

    15% {
        -webkit-transform: translateX(calc(-0px / 2)) rotate(calc(-10deg / 1.8));
        transform: translateX(calc(-0px / 2)) rotate(calc(-10deg / 1.8));
    }

    20% {
        -webkit-transform: translateX(calc(0px / 3.3)) rotate(calc(10deg / 3));
        transform: translateX(calc(0px / 3.3)) rotate(calc(10deg / 3));
    }

    25% {
        -webkit-transform: translateX(calc(-0px / 5.5)) rotate(calc(-10deg / 5));
        transform: translateX(calc(-0px / 5.5)) rotate(calc(-10deg / 5));
    }
}

.links {
    margin: 0;
    color: white;
    margin-right: 2em;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
}

.links:hover
{
    /* */
}

.logo {
    width: 12rem;
    margin-right: 1.3rem;
}

.logo img {
    width: 100%;
}

.header {
    /* background: linear-gradient(180deg, #05224A 0%, #0D4C63 100%)!important; */
    background-image: url('../img/backgrounds/xalatath_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 -360px;
    padding: 0.8em 0;
}

.themeController{
    background: transparent;
    border: 0;
    border-radius: 66px;
    padding: 0;
}

.themeBtn, .light, .dark {
    border-radius: 33px;
    width: 8rem;
    height: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dark {
    background-color: black;
    border: 1px rgba(37, 75, 207, 1) solid;
    box-shadow: 0px 0px 4px 0px #696bff inset;
    gap: 2.3em;
}

#night-decoration {
    width: 31px;
    height: 24px;
}

#moon {
    width: 1.75rem;
    height: 1.75rem;
    background-color: gray;
    border-radius: 66px;
}

#moon>svg {
    width: 100%;
    height: 100%;
}

.light {
    background: #C5EEFF;
    border: 1px solid #FFEB80;
    box-shadow: 0px 0px 4px 0px #FFE500 inset;
    gap: 1.1em;
}

#sun {
    width: 35.448px;
    height: 35.448px;
    background-color: #FFC909;
    border-radius: 66px;
}

#day-decoration {
    width: 52.239px;
    height: 30.808px;
}

#day-decoration circle{
    fill: rgb(255, 255, 255);
}