.rulesIntro
{
  font-family: 'Poppins-Light';
  width: 50%;
  margin: auto;
  padding-bottom: 1em;
  margin-bottom: 1em;
}

.bottomBorder
{

  border-bottom: 3px solid var(--color-secondary);
}

.rulesIntro p
{
  font-size: var(--font-size);
  text-align: justify;
}

.raidRequirements
{
  width: 60%;
  margin: auto;
  margin-bottom: 3em;
}

.rulesSignature
{
  text-align: right !important;
  font-size: calc(var(--font-size) * 1.6) !important;
  font-style: italic;
  margin-top: 0.5em;
  margin-right: 1.5em;
}

/* Rules FAQ accordion */
.accordionRoot
{
  margin: auto;
  width: 60%;
}

.accordionTitle
{
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.accordionSection
{
  font-size: var(--font-size) !important;
  font-family: 'Poppins-Light' !important;
  margin-left: 30px;
  margin-right: 30px;
}

/* Raid Rules Requirements*/

.requirementsList
{
  margin: 2em 0;
  width: 60%;
  text-align: left;
  margin-left: 3.5em;
}

.requirementsList > li
{
  margin-bottom: 0.5em !important;
  display: block;
  border-bottom: 0.15em solid var(--color-secondary);
}

.globalList
{
  margin: auto;
  width: 80%;
  text-align: center;
}

.globalList > li
{
  margin-bottom: 1em !important;
  display: block;
}

.rightFloat
{
  float: right;
}

.fa-ul > li
{
  margin-bottom: 3px;
}

.rulesChecklist
{
  text-align: center;
  margin: auto;
  width: 70%;
}

/* Rules Layout styling */

.rulesLinks
{
  text-align: center;
}

/* Bank rules styling */

.bankCol
{
  margin: auto;
  /* border: 1px solid blue; */
  width: 75%;
  padding: 5px;
}

.craftColumns
{
  column-count: 2;
  column-gap: 5px;
  column-rule: 3px solid var(--color-secondary);
}

.activityColumns
{
  column-count: 2;
  column-gap: 5px;
  column-rule: 3px solid var(--color-secondary);
}

.craftCol
{
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  margin: auto;
  /* border: 1px solid blue; */
  width: 75%;
  padding: 5px;
}

.bankCol > h3
{
  text-align: center;
  border-bottom: 3px solid var(--color-secondary);
  padding-bottom: 0.6em;
  margin-bottom: 0.6em;
}

.bankCol > table 
{
  font-size: var(--font-size);
  margin: auto;
  text-align: center;
  table-layout: fixed;
  
}

.bankCol > table > tr
{
  padding-bottom: 1em;
}

.bankCol > table > tr > td
{
  border-bottom: 2px solid var(--color-secondary);
}

.bankCol > table > tr > td + td
{
  border-left: 2px solid var(--color-secondary);
}