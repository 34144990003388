.info
{
  text-align: center;
  padding-bottom: 1em;
}

.roster
{
  /* -webkit-column-count: 6;
  -moz-column-count: 6;
  column-count: 6;

  -webkit-column-gap: 0.5em;
  -moz-column-gap: 0.5em;
  column-gap: 0.5em;

  column-fill: balance-all;
  padding: 0; */

  margin-left: auto;
  margin-right: auto;

  display: flex;
  align-self: self-start;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: center;
}

.rosterSearch
{
  font-family: 'Poppins', sans-serif !important;
  margin: auto;
  width: 35%;
}

.roster p
{
  margin-block-start: 0;
  margin-block-end: 0;
}

.roster button
{
  min-width: 0;
  width: 1em;
  float: right;
}

/* Generic Guild Data Titles */
.memberTitle
{
  margin-block-start: 1em;
  font-size: calc(var(--font-size) * 2);
}

.achieveTitle
{
  font-size: calc(var(--font-size) * 1.6);
}