.column
{
    width: 50%;
    display: inline-block;
}

.left
{
    left: 0;
    width: 70%;
    margin-right: 10px;
}

.right
{
    right: 0;
    width: 20%;
    margin-left: 10px;
}

.column p
{
    float: none;
}

.row:after 
{
    content: "";
    display: table;
    clear: both;
}

.progBar
{
    display: block;
    margin: auto;
    margin-bottom: 20px;
}

.peep
{
    width:100%;
    height: 400px;
    background-color: transparent;
}

.tgp_table
{
    width: 80%;
    display: table;
    margin: 0 auto;
}

.TGPrarity0
{
    background-color: #ffffff;
    color: black;
}
.TGPrarity1
{
    background-color: #1eff00;
    color: black;
}
.TGPrarity2 { background-color: #0070dd; }
.TGPrarity3 { background-color: #a335ee; }
.TGPrarity4 { background-color: #ff8000; }

.tgp_table > tbody > tr > td, 
.tgp_table > tbody > tr > th
{ column-width: 60px !important; text-align: center; }

.tgp_table > tbody > tr > td:nth-child(11), 
.tgp_table > tbody > tr > th:nth-child(11)
{ border-right: 15mm solid #0E3A4A; }

.tgp_table > tbody > tr > td:nth-child(3), 
.tgp_table > tbody > tr > th:nth-child(3)
{ border-right: 15mm solid #0E3A4A; }

.tgp_gold { background-color: #fdd017; }

.tgp_silver { background-color: #c0c0c0; }

.tgp_bronze { background-color: #8c7853; }

.tgp_completion_1 { background-color: #9d9d9d; }
.tgp_completion_2 { background-color: #ffffff; color: black;}
.tgp_completion_3 { background-color: #1eff00; color: black;}
.tgp_completion_4 { background-color: #0070dd; }
.tgp_completion_5 { background-color: #a335ee; }
.tgp_completion_6 { background-color: #ff8000; }
.tgp_completion_7 { background-color: #e6cc80; }